import '../assets/css/main.css';
import Hero from "./Hero";
import About from "./About";
import Resume from "./Resume";
import WorkExperiences from "./WorkExperiences";
import KeyHighlights from "./KeyHighlights";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

function Main() {

  return (
      <main className="main">
          <Hero />
          <About />
          <KeyHighlights />
          <Resume />
          <WorkExperiences />
          <Testimonials />
          {/*}} <Contact />{*/}
      </main>
  );
}

export default Main;