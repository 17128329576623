import '../assets/css/main.css';

function Resume() {

    return (
        <section id="resume" className="resume section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Resume</h2>
                <p>Highlights of my last 7 years of professional experience along with my educational background. Please
                    download my resume PDF for more detailed information regarding my work experience and additional
                    details.</p>
            </div>

            <div className="container">

                <div className="row">

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <h3 className="resume-title">Summary</h3>

                        <div className="resume-item pb-0">
                            <h4>Jose Bravo-Tejeda</h4>
                            <p>
                                <em>
                                    A technology leader with deep experience in Java/Spring development, working in secure financial environments, building successful teams both locally and abroad. Takes great interest in mentoring developers in their professional development. Loves coding. Skilled overseeing knowledge transfer ensuring goals are consistent with objectives.
                                </em>
                            </p>
                            <ul>
                                <li>Greater Los Angeles County (open to In-Office, Hybrid, or Remote)</li>
                                <li>323 496 9047</li>
                                <li>jose_bravo@hotmail.com</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Professional Experience</h3>


                        <div className="resume-item">
                            <h4>Senior Software Engineering Manager</h4>
                            <h5>2018 - 2024</h5>
                            <p><em>Visa, Inc. / Verifi Inc. (business acquired), Los Angeles, CA </em></p>
                            <ul>
                                <li>
                                    Oversaw day-to-day management of OI and CBR teams. Led these teams to deliver key deliverables while addressing on-going, operational
                                    requirements. Delivering these features allowed the organization to remain competitive in the business space while ensuring that
                                    operational and security needs were met. Met time commitment to the business 90% of the time ensuring that software deliverables were ready
                                    to market as promised.
                                </li>
                                <li>
                                    Collaborated with the team on knowledge transfer activities as we migrated Verifi application responsibilities from contractors to Visa
                                    FTEs as part of the Verifi-to-Visa migration initiative. This included a platform rewrite from PHP/Play to Spring frameworks. 100% of
                                    intended services were either migrated or rewritten and are now hosted on Visa platforms ensuring that all services follow Visa technology
                                    standards while minimizing business impact to customers.
                                </li>
                                <li>
                                    Participated in design/architecture discussions, peer reviews, and led platform modernization discussions. Coded on various platforms on a
                                    variety of platforms such as Java, Python, Lua, and others. These efforts demonstrated technical proficiency and more effective leadership.
                                    Led the Cyber Security remediation as part of technology migration efforts to focus efforts on addressing the urgent security items. Due to
                                    these efforts, suffered 0 security breaches on this code as the team patched up security day findings, including zero-day security
                                    vulnerabilities. Met date commitments 95% of the time.
                                </li>
                                <li>
                                    Managed the day-to-day operations of all Cybersecurity initiatives, migration initiatives, audits, and attestations, allowing teams to
                                    focus 90% of their efforts on addressing requirements and meeting commitments on schedule.
                                </li>
                                <li>
                                    Worked to stand up an engineering team in India to assist with development, hiring staff, setting up meets between U.S. and India-based
                                    teams to assist with knowledge transfer, setting milestones to ensure that expectations were being met, and working with product/project
                                    management to setup Jira projects, backlog, and priorities for the team, ultimately increasing headcount by 120%. This additional headcount
                                    increased our overall velocity by 85%, which allowed deliverables to be completed 1 quarter earlier than originally planned.
                                </li>
                                <li>
                                    Invested efforts in ensuring that direct reports were adequately mentored, leading to 3 direct reports promoted to senior roles despite
                                    intense competition. Supervised a technical product manager that was having performance issues (and was under a PIP) and was able to
                                    successfully lead the employee out of the plan and is finding success in the role. These efforts encouraged strong morale resulting in high
                                    satisfaction and reduced turnover rates among staff with a net gain of headcount over the last 3 years.
                                </li>
                                <li>
                                    Led initiatives targeted at encouraging positive morale in teams. Organized monthly lunch meets out of the office, brown bag lunch and learn
                                    and offsite events, which resulted in high morale and team camaraderie.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>Supplemental Courses in Leadership</h4>
                            <h5>2024</h5>
                            <p><em>College of the Canyons, Valencia, CA</em></p>
                            <p>Taking additional supplemental courses to further build on my leadership skills in
                                software engineering. These courses include:
                                <br/>
                                <br/>
                                <ul>
                                    <li>Successful Managing and Developing People</li>
                                    <li>Critical Thinking / Problem Solving</li>
                                    <li>Emerging Leaders</li>
                                    <li>Negotiating</li>
                                </ul>
                            </p>
                        </div>
                        <div className="resume-item">
                            <h4>UCLA Extension - Technical Management Program</h4>
                            <h5>2019</h5>
                            <p><em>University of California, Los Angeles</em></p>
                            <p>I was selected by my employer to attend this program at UCLA considering the
                                contributions I
                                delivered and the opportunity that this program can further refine my skills as a
                                leader.
                                Courses
                                included:
                                <br/>
                                <br/>
                                <ul>
                                    <li>Adaptive Project Management</li>
                                    <li>Creating and Sustaining Excellence in the Workplace</li>
                                    <li>Continue to Succeed as a Leader</li>
                                    <li>People, Power, and Projects: Managing the Human Dynamic</li>
                                </ul>
                            </p>
                        </div>
                        <div className="resume-item">
                            <h4>Bachelor of Science, Computer Information Systems</h4>
                            <h5>2010</h5>
                            <p><em>California State University, Los Angeles</em></p>
                            <p>Received my undergraduate degree in Computer information Systems</p>
                        </div>

                        <div className="resume-item">
                            <h4>Associate of Arts, Liberal Arts</h4>
                            <h5>2006</h5>
                            <p><em>East Los Angeles College</em></p>
                            <p>Received my associates degree in Liberal Arts. Additionally, I was able to apply my
                                college
                                credits towards my undergraduate degree.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Resume;